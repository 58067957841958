import React, { useState, useEffect, useContext, useMemo } from 'react';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import styled from 'styled-components';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, faEdit } from '@fortawesome/fontawesome-free-solid/';
import Loader from 'react-loader-spinner';
/* Popup style */
const PopupWrapper = styled.div`
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 999999;
`;

const Box = styled.div`
	position: relative;
	width: 85%;
	max-width: 450px;
	margin: 0 auto;
	height: auto;
	max-height: 70vh;
	margin-top: calc(100vh - 85vh - 20px);
	background: #fff;
	border-radius: 4px;
	padding: 20px;
	border: 1px solid #999;
	overflow: auto;
`;

const CloseIcon = styled.span`
	content: 'x';
	cursor: pointer;
	position: fixed;
	right: calc(15% - 30px);
	top: calc(100vh - 85vh - 33px);
	background: #ededed;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	line-height: 20px;
	text-align: center;
	border: 1px solid #999;
	font-size: 20px;
`;
const ChangeEmailInput = styled.input`
	width: 100%;
	height: 45px;
	background-color: #f1f1f1;
	border: #979797 solid 1px;
	padding: 0px 10px;
`;
const UpdateButton = styled.button`
	// width: 100%;
	// margin-top: 14px;
	// border: none;
	// padding: 10px 0px;
	// font-size: 17px;
	// background-color: #282c37;
	// color: #fff;
	// border-radius: 3px;
	padding: 13px;
	line-height: 10px;
	height: 100%;
	width: 125px;
	margin-right: 15px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 14px;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	color: #ac323d;
	background-color: #fff;
	border: 2px solid #ac323d;
	&:hover {
		background-color: #ac323d;
		color: #fff;
	}
`;
const CancelButton = styled.button`
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	margin-right: 0;
	padding: 13px;
	cursor: pointer;
	border-radius: 10px;
	font-size: 14px;
	line-height: 10px;
	height: 100%;
	width: 125px;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
`;
const ChangeEmailLabel = styled.label`
	margin-bottom: 10px;
	color: #94b3db;
	font-size: 22px;
	margin-top: 10px;
	display: flex;
	justify-content: center;
`;

const ButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	margin-top: 10px;
`;

const SentMessageWrapper = styled.span`
	position: absolute;
    display: block;
    background-color: bisque;
    width: 100%;
    padding: 5px 0;
    top: 0px;
    left: 0;
    text-align: center;
    font-weight: 500;
`;

const Popup = props => {

	const [userId, setUserId] = useState('');
	const { authState, isProUser, isTrialUser, hasProAccount } = useContext(AuthContext);
	const [oldEmail, setOldEmail] = useState(authState.user.email);
	const [sentMessage, setSentMessage] = useState('');
	const [showLoader, setShowLoader] = useState(false);

	const [newEmail, setNewEmail] = useState(authState.user.email);
	const [newCountry, setNewCountry] = useState(authState.user.country);
	const [newPostalCode, setNewPostalCode] = useState(authState.user.zip);
	const [value, setValue] = useState(authState.user.country)
	const options = useMemo(() => countryList().getData(), [])

	useEffect(() =>{
		let userData = fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/findByEmail/${authState.user.email}`)
			.then(res => res.json())
			.then(userData => {
				setNewCountry(userData.country);
				setNewPostalCode(userData.zip);
			});
	},[showLoader]);
	const logout = () => {
		localStorage.clear();
		localStorage.setItem('prev-email', authState.user.email);
		props.history.push('/login');
	};
	const changeHandler = value => {
		setNewCountry(value.value)
		console.log("onchange value",value)	
  	}
  	
	const updateEmail = async () => {
		setShowLoader(true);
		try {
			let sendResetEmailRes = await fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/sendEmailReset`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ emailAddress: oldEmail, newEmailAddress: newEmail, country: newCountry, postalCode: newPostalCode }),
			})
			.then(res => res.json())
			.then(response => {
				
				setSentMessage(response);
				setShowLoader(false);
				//props.handleClose();
			});
			
			

			// if (oldEmail && newEmail) {

			//   let changePasswordRes = await fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/changeEmail`, {
			//     method: 'POST',
			//     headers: { 'Content-Type': 'application/json' },
			//     body: JSON.stringify({ oldEmail, newEmail, userId: authState.user.id }),
			//   }).then(res => res.json());
			// }
			// logout();
		} catch (err) {
			
		}
	};
	return (
		<>
			<PopupWrapper>
				<Box>
					{showLoader && (
						<Loader
							type='TailSpin'
							color='#000'
							height={25}
							width={25}
							style={{
								position: 'absolute',
								right: '34px',
								bottom: '31px',
							}}
						/>
					)}
					{/* <FontAwesomeIcon
						icon={faTimes}
						style={{
							float: 'right',
							cursor: 'pointer',
							margin: '0px 0px 15px 0px',
						}}
						onClick={props.handleClose}
					/> */}
					<SentMessageWrapper>{sentMessage}</SentMessageWrapper>
					<ChangeEmailLabel>Update Profile</ChangeEmailLabel>
					<label>Email Address</label>
					<ChangeEmailInput
						type='email'
						name='newEmail'
						placeholder='New Email Address'
						value={newEmail}
						onChange={e => setNewEmail(e.target.value)}
					/>
					<br /><br />
					<label>Country</label>
					<Select 
					placeholder='Select Country'
					options={options} 
					name='newCountry'
					value={options.filter(function(option) {return option.value === `${newCountry}`})} 
					style={{
						width:'100%'
					}}
					onChange={changeHandler} />
					
					<br />
					<label>Zip Code</label>
					<ChangeEmailInput
						type='text'
						name='newPostalCode'
						placeholder='New Zip Code'
						value={newPostalCode}
						onChange={e => setNewPostalCode(e.target.value)}
					/>
					<ButtonsContainer>
						<UpdateButton type='button' onClick={() => updateEmail()}>
							Update
						</UpdateButton>
						<CancelButton type='button' onClick={props.handleClose}>
							Close
						</CancelButton>
					</ButtonsContainer>
				</Box>
			</PopupWrapper>
		</>
	);
};

export default Popup;
