import React, { useState, useEffect, useContext, useMemo  } from 'react';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ColorLogo from '../../assets/logo_color.svg';
import '../../assets/css/login-register.css';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { loadStripe } from '@stripe/stripe-js';
import Loader from 'react-loader-spinner';
import giftImage from '../../assets/gift.png';
const TopDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #f1f1f1;
	padding-top: 44px;
`;

const SignLogWrapper = styled.h1`
	text-align: center;
	font-size: 32px;
	color: #dedede;
	width: 100%;
	margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
`;

const LeftRegisterWrapper = styled.div`
	width: 100%;
	padding: 20px 20px; /* Added padding on the sides */
	text-align: center;
	margin-bottom: 20px;

	@media (max-width: 980px) {
		padding: 20px 10px; /* Adjust padding for smaller screens if needed */
	}
`;

const FormAndRightWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 20px;
	//   border: 1px solid red;
	@media (max-width: 980px) {
		flex-direction: column;
		align-items: center;
	}
`;

const FormWrapper = styled.div`
	flex: 1;
	padding: 20px;
	background-color: #fff;
	border: 1px solid #d3d3d3;
	margin-right: 10px;
	border-radius: 5px 0 0 5px;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

	@media (max-width: 980px) {
		margin-right: 0;
		width: 100%;
		border-radius: 5px 5px 0 0;
	}
`;

const RightRegisterWrapper = styled.div`
	flex: 1;
	padding: 20px;
	background-color: #fff;
	border: 1px solid #d3d3d3;
	border-radius: 0 5px 5px 0;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

	@media (max-width: 980px) {
		margin-left: 0;
		width: 100%;
		border-radius: 0 0 5px 5px;
	}
`;

const LogoImage = styled.img`
	position: absolute;
	top: 32px;
	left: 32px;
	width: 124px;
	// height: 41px;
	@media (max-width: 680px) {
		display: none;
	}
`;

const RegisterWrapper = styled.div`
	display: flex;
	flex: 1;
	justify-content: space-between;

	@media (max-width: 980px) {
		flex-direction: column;
		width: 100%;
	}
`;

const LoginTab = styled.span`
	color: #fff;
	font-size: 18px;
	cursor: auto;
	position: relative;
	display: inline-block;
	padding: 8px 18px;
	border: #007bff solid 1px;
	border-radius: 5px 0px 0px 5px;
	a:hover {
		text-decoration: none;
	}
`;

const SignupText = styled.div`
	color: #000;
	font-size: 26px;
	font-weight: normal;
	text-align: center;
	margin-bottom: 5px;
	padding: 5px 0; /* Adjust padding to match the vertical spacing */
`;

const SignupTab = styled.div`
	color: #fff;
	font-size: 18px;
	cursor: auto;
	position: relative;
	display: inline-block;
	padding: 8px 18px;
	border: #007bff solid 1px;
	border-radius: 0px 5px 5px 0px;
	background-color: #007bff;
`;

const ButtonHolder = styled.div`
	position: relative;
`;

const FormGroup = styled.div`
	margin-bottom: 10px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
`;

const FormInput = styled.input`
	border: #d3d3d3 solid 1px;
	height: 40px;
	border-radius: 4px;
	margin: 0 4px;
	padding: 0 7px;
	flex: 1;
	&:focus {
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
		outline-width: 0;
	}
`;

const SelectInput = styled.select`
	border: #d3d3d3 solid 1px;
	height: 40px;
	border-radius: 4px;
	margin: 0 4px;
	padding: 0 7px;
	flex: 1;
	&:focus {
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
		outline-width: 0;
	}
`;

const FormInputLabel = styled.label`
	font-size: 14px;
	width: 100%;
	margin-top: 10px;
	font-weight: 500;
`;
const FormCardInput = styled.input`
	width: 100%;
	border: #d3d3d3 solid 1px;
	height: 40px;
	border-radius: 4px;
	margin: 0px 4px 10px 4px;
	padding: 0 7px;
	&:focus {
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
		outline-width: 0;
	}
`;
const PermissionInputWrapper = styled.div`
	padding-bottom: 1rem;
	width: 100%;
	text-align: left;
`;

const FormBtn = styled.button`
	color: #fff;
	font-size: 18px;
	cursor: auto;
	position: relative;
	display: inline-block;
	padding: 8px 18px;
	border: #007bff solid 1px;
	border-radius: 5px;
	background-color: #007bff;
	margin-top: 20px;
	width: 100%;
	&:hover {
		color: #f8f8ff;
		background-color: #000;
	}
`;

const ErrorWrapper = styled.div`
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
	padding: 0.75rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
`;
const SideText = styled.div`
	margin-bottom: 1rem;
`;
const GiftText = styled.div`
	margin-bottom: 10px;
	font-weight: 500;
	font-size: 14px;
`;
const GiftLabel = styled.div`
	font-weight: 400;
	font-size: 14px;
	color: #607d8b;
`;

// const StripeBtn = styled.button`

// 	cursor: pointer;

// 	color: #000;

// 	border: 2px solid black;

// 	font-weight: 400;

// 	text-align: center;

// 	width: 100%;

// 	padding: 10px;

// 	background-color: #fff;

// 	&:hover {

// 		color: #fff;

// 		background-color: #000;

// 	}

// `;

const Signup = props => {
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [giverEmail, setGiverEmail] = useState('');
	const [giverName, setGiverName] = useState('');
	const [cardNum, setCardNum] = useState('');
	const [cardExp, setCardExp] = useState('');
	const [cardCvc, setCardCvc] = useState('');
	const [cardHolder, setCardHolder] = useState('');
	const [postalCode, setPostalCode] = useState('');
	const [country, setCountry] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [acknowledgement, setAcknowledgement] = useState(false);
	const [gift, setGift] = useState(false);
	const [error, setError] = useState('');
	const [checkMonthly, setCheckMonthly] = useState(true);
	const [checkYearly, setCheckYearly] = useState(false);

	const [checkYearly3, setCheckYearly3] = useState(false);
	const [checkYearly5, setCheckYearly5] = useState(false);
	const [checkYearly10, setCheckYearly10] = useState(false);
	const [checkYearlyGift, setCheckYearlyGift] = useState(false);
	const [checkMonthlyGift, setCheckMonthlyGift] = useState(false);
	const [renewGift, setRenewGift] = useState(false);

	const [select, setSelect] = useState('');

	const [showLoader, setShowLoader] = useState(false);

	const { registerUser } = useContext(AuthContext);

	const [value, setValue] = useState('')
  	const options = useMemo(() => countryList().getData(), [])

	const handleChangeYearly = e => {
		setCheckMonthly(false);
		setCheckYearly(true);
		setCheckYearly3(false);
		setCheckYearly5(false);
		setCheckYearly10(false);
		setCheckYearlyGift(false);
		setCheckMonthlyGift(false);
	};

	const handleChangeMonthly = e => {
		setCheckMonthly(true);
		setCheckYearly(false);
		setCheckYearly3(false);
		setCheckYearly5(false);
		setCheckYearly10(false);
		setCheckYearlyGift(false);
		setCheckMonthlyGift(false);
	};

	const handleChangeYearly3 = e => {
		setCheckMonthly(false);
		setCheckYearly(false);
		setCheckYearly3(true);
		setCheckYearly5(false);
		setCheckYearly10(false);
		setCheckYearlyGift(false);
		setCheckMonthlyGift(false);
	};

	const handleChangeYearly5 = e => {
		setCheckMonthly(false);
		setCheckYearly(false);
		setCheckYearly3(false);
		setCheckYearly5(true);
		setCheckYearly10(false);
		setCheckYearlyGift(false);
		setCheckMonthlyGift(false);
	};

	const handleChangeYearly10 = e => {
		setCheckMonthly(false);
		setCheckYearly(false);
		setCheckYearly3(false);
		setCheckYearly5(false);
		setCheckYearly10(true);
		setCheckYearlyGift(false);
		setCheckMonthlyGift(false);
	};

	const handleChangeYearlyGift = e => {
		setCheckMonthly(false);
		setCheckYearly(false);
		setCheckYearly3(false);
		setCheckYearly5(false);
		setCheckYearly10(false);
		setCheckYearlyGift(true);
		setCheckMonthlyGift(false);
	};

	const handleChangeMonthlyGift = e => {
		setCheckMonthly(false);
		setCheckYearly(false);
		setCheckYearly3(false);
		setCheckYearly5(false);
		setCheckYearly10(false);
		setCheckYearlyGift(false);
		setCheckMonthlyGift(true);
	};
	const cardExpInput = ele => {
		if (ele.length === 2) ele = ele + '/';
		else if (ele.length === 3 && ele.charAt(2) === '/') ele = ele.replace('/', '');

		setCardExp(ele);
	};

	const makeid = length => {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		let counter = 0;
		while (counter < length) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
			counter += 1;
		}
		return result;
	};

	const changeHandler = value => {
		setCountry(value)
		console.log("Country",country);
  	}

	const register = e => {
		e.preventDefault();
		// if (email === '' || name === '' || password === '' || passwordConfirm === '') {
		// 	return setError('Please fill out all fields.');
		// }
		// if (password !== passwordConfirm) {
		// 	return setError('Uh oh, password fields are different.');
		// }
		try {
			setError('');

			registerUser(name, email, password, props);
		} catch (error) {
			return props.history.push('/signup');
		}
	};

	const CountrySelector = e => {

	}

	const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_API_KEY}`);

	const handleClickMonthly = async event => {
		window.onbeforeunload = null;

		var referral = '';
		// rewardful('ready', function() {
		//     if(Rewardful.referral) {
		//     	referral=Rewardful.referral;
		//       console.log('Current referral ID: ', Rewardful.referral);
		// 	  console.log('Current affiliate data: ', Rewardful.affiliate);

		//     } else {
		//       console.log('No referral present.');
		//     }
		//   });
		const stripe = await stripePromise;
		const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/createMonthlyCheckout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customer_email: `${email}`,
				customer_name: `${name}`,
				card_number: `${cardNum}`,
				card_exp: `${cardExp}`,
				card_cvc: `${cardCvc}`,
				card_holder: `${cardHolder}`,
				country: `${country.value}`,
				postal_code: `${postalCode}`,
				referral: window.Rewardful.referral,
			}),
		})
			.then(res => res.json())
			.then(response => {
				if (response.id) {
					// register(event);
					window.location.replace('https://scripturenotes.com/thank-you?customer_email=' + email);
				} else {
					setShowLoader(false);
					event.target.disabled = false;
					let deleteUser = fetch(`${process.env.REACT_APP_BASE_URL}/auth/deleteUser/${email}`, { method: 'Get' }).then(res => res.json());
					if (response.message.trim() == 'Invalid integer:') {
						return setError('Invalid Card Expiry');
					} else {
						return setError(response.message);
					}
				}
			});
	};

	const handleClickYearly = async event => {
		window.onbeforeunload = null;
		const stripe = await stripePromise;
		const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/createYearlyCheckout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customer_email: `${email}`,
				customer_name: `${name}`,
				card_number: `${cardNum}`,
				card_exp: `${cardExp}`,
				card_cvc: `${cardCvc}`,
				card_holder: `${cardHolder}`,
				country: `${country.value}`,
				postal_code: `${postalCode}`,
				referral: window.Rewardful.referral,
			}),
		})
			.then(res => res.json())
			.then(response => {
				if (response.id) {
					// register(event);
					window.location.replace('https://scripturenotes.com/thank-you?customer_email=' + email);
				} else {
					setShowLoader(false);
					event.target.disabled = false;
					let deleteUser = fetch(`${process.env.REACT_APP_BASE_URL}/auth/deleteUser/${email}`, { method: 'Get' }).then(res => res.json());
					if (response.message.trim() == 'Invalid integer:') {
						return setError('Invalid Card Expiry');
					} else {
						return setError(response.message);
					}
				}
			});
	};
	const handleClickYearlyGift = async event => {
		window.onbeforeunload = null;
		const stripe = await stripePromise;
		const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/createYearlyGiftCheckout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customer_email: `${email}`,
				customer_name: `${name}`,
				giver_email: `${giverEmail}`,
				giver_name: `${giverName}`,
				card_number: `${cardNum}`,
				card_exp: `${cardExp}`,
				card_cvc: `${cardCvc}`,
				card_holder: `${cardHolder}`,
				country: `${country.value}`,
				postal_code: `${postalCode}`,
				renew_gift: `${renewGift}`,
				password: `${password}`,
				referral: window.Rewardful.referral,
			}),
		})
			.then(res => res.json())
			.then(response => {
				if (response.id) {
					// register(event);
					window.location.replace('https://scripturenotes.com/thank-you?customer_email=' + email);
				} else {
					setShowLoader(false);
					event.target.disabled = false;
					let deleteUser = fetch(`${process.env.REACT_APP_BASE_URL}/auth/deleteUser/${email}`, { method: 'Get' }).then(res => res.json());
					if (response.message.trim() == 'Invalid integer:') {
						return setError('Invalid Card Expiry');
					} else {
						return setError(response.message);
					}
				}
			});
	};
	const handleClickMonthlyGift = async event => {
		window.onbeforeunload = null;
		const stripe = await stripePromise;
		const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/createMonthlyGiftCheckout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customer_email: `${email}`,
				customer_name: `${name}`,
				giver_email: `${giverEmail}`,
				giver_name: `${giverName}`,
				card_number: `${cardNum}`,
				card_exp: `${cardExp}`,
				card_cvc: `${cardCvc}`,
				card_holder: `${cardHolder}`,
				country: `${country.value}`,
				postal_code: `${postalCode}`,
				renew_gift: `${renewGift}`,
				password: `${password}`,
				referral: window.Rewardful.referral,
			}),
		})
			.then(res => res.json())
			.then(response => {
				if (response.id) {
					// register(event);
					window.location.replace('https://scripturenotes.com/thank-you?customer_email=' + email);
				} else {
					setShowLoader(false);
					event.target.disabled = false;
					let deleteUser = fetch(`${process.env.REACT_APP_BASE_URL}/auth/deleteUser/${email}`, { method: 'Get' }).then(res => res.json());
					if (response.message.trim() == 'Invalid integer:') {
						return setError('Invalid Card Expiry');
					} else {
						return setError(response.message);
					}
				}
			});
	};
	const handleClickYearly3 = async event => {
		window.onbeforeunload = null;
		const stripe = await stripePromise;
		const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/create3YearlyGiftCheckout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customer_email: `${email}`,
				customer_name: `${name}`,
				giver_email: `${giverEmail}`,
				giver_name: `${giverName}`,
				card_number: `${cardNum}`,
				card_exp: `${cardExp}`,
				card_cvc: `${cardCvc}`,
				card_holder: `${cardHolder}`,
				country: `${country.value}`,
				postal_code: `${postalCode}`,
				renew_gift: `${renewGift}`,
				password: `${password}`,
				gift: `${gift}`,
				referral: window.Rewardful.referral,
			}),
		})
			.then(res => res.json())
			.then(response => {
				if (response.id) {
					// register(event);
					window.location.replace('https://scripturenotes.com/thank-you?customer_email=' + email);
				} else {
					setShowLoader(false);
					event.target.disabled = false;
					let deleteUser = fetch(`${process.env.REACT_APP_BASE_URL}/auth/deleteUser/${email}`, { method: 'Get' }).then(res => res.json());
					if (response.message.trim() == 'Invalid integer:') {
						return setError('Invalid Card Expiry');
					} else {
						return setError(response.message);
					}
				}
			});
	};
	const handleClickYearly5 = async event => {
		window.onbeforeunload = null;
		const stripe = await stripePromise;
		const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/create5YearlyGiftCheckout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customer_email: `${email}`,
				customer_name: `${name}`,
				giver_email: `${giverEmail}`,
				giver_name: `${giverName}`,
				card_number: `${cardNum}`,
				card_exp: `${cardExp}`,
				card_cvc: `${cardCvc}`,
				card_holder: `${cardHolder}`,
				country: `${country.value}`,
				postal_code: `${postalCode}`,
				renew_gift: `${renewGift}`,
				password: `${password}`,
				gift: `${gift}`,
				referral: window.Rewardful.referral,
			}),
		})
			.then(res => res.json())
			.then(response => {
				if (response.id) {
					// register(event);
					window.location.replace('https://scripturenotes.com/thank-you?customer_email=' + email);
				} else {
					setShowLoader(false);
					event.target.disabled = false;
					let deleteUser = fetch(`${process.env.REACT_APP_BASE_URL}/auth/deleteUser/${email}`, { method: 'Get' }).then(res => res.json());
					if (response.message.trim() == 'Invalid integer:') {
						return setError('Invalid Card Expiry');
					} else {
						return setError(response.message);
					}
				}
			});
	};
	const handleClickYearly10 = async event => {
		window.onbeforeunload = null;
		const stripe = await stripePromise;
		const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/create10YearlyGiftCheckout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customer_email: `${email}`,
				customer_name: `${name}`,
				giver_email: `${giverEmail}`,
				giver_name: `${giverName}`,
				card_number: `${cardNum}`,
				card_exp: `${cardExp}`,
				card_cvc: `${cardCvc}`,
				card_holder: `${cardHolder}`,
				country: `${country.value}`,
				postal_code: `${postalCode}`,
				renew_gift: `${renewGift}`,
				password: `${password}`,
				gift: `${gift}`,
				referral: window.Rewardful.referral,
			}),
		})
			.then(res => res.json())
			.then(response => {
				if (response.id) {
					// register(event);
					window.location.replace('https://scripturenotes.com/thank-you?customer_email=' + email);
				} else {
					setShowLoader(false);
					event.target.disabled = false;
					let deleteUser = fetch(`${process.env.REACT_APP_BASE_URL}/auth/deleteUser/${email}`, { method: 'Get' }).then(res => res.json());
					if (response.message.trim() == 'Invalid integer:') {
						return setError('Invalid Card Expiry');
					} else {
						return setError(response.message);
					}
				}
			});
	};
	const validateEmail = email => {
		return email.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};
	const handleExistingUser = async (selection, e) => {
		e.persist();
		e.target.disabled = true;
		
		if (gift) {
			if (email === '' || name === '' || cardCvc === '' || giverEmail === '' || giverName === '') {
				e.target.disabled = false;
				return setError('Please fill out all fields.');
			}
		} else {
			if (email === '' || name === '' || password === '' || passwordConfirm === '' || cardCvc === '' || country.value === '' || postalCode === '') {
				e.target.disabled = false;
				return setError('Please fill out all fields.');
			}
		}
		if (!validateEmail(email)) {
			e.target.disabled = false;
			return setError('Please enter a valid email address.');
		}
		if (password !== passwordConfirm && gift === false) {
			e.target.disabled = false;
			return setError('Uh oh, password fields are different.');
		}
		if (acknowledgement === false) {
			e.target.disabled = false;
			return setError('Please agree to terms and conditions.');
		} else {
			if (selection === 'monthly') {
				await fetch(`${process.env.REACT_APP_BASE_URL}/auth/existingUser/${email}`, {
					method: 'Get',
				})
					.then(res => res.json())
					.then(response => {
						if (response === 'OK') {
							setShowLoader(true);
							register(e);
							handleClickMonthly(e);
						} else {
							e.target.disabled = false;
							return setError(response);
						}
					});
			}
			if (selection === 'yearly') {
				await fetch(`${process.env.REACT_APP_BASE_URL}/auth/existingUser/${email}`, {
					method: 'Get',
				})
					.then(res => res.json())
					.then(response => {
						if (response === 'OK') {
							setShowLoader(true);
							register(e);
							handleClickYearly(e);
						} else {
							e.target.disabled = false;
							return setError(response);
						}
					});
			}
			if (selection === 'monthly_gift') {
				setPassword(makeid(10));
				await fetch(`${process.env.REACT_APP_BASE_URL}/auth/existingUser/${email}`, {
					method: 'Get',
				})
					.then(res => res.json())
					.then(response => {
						if (response === 'OK') {
							setShowLoader(true);
							register(e);
							handleClickMonthlyGift(e);
						} else {
							e.target.disabled = false;
							return setError(response);
						}
					});
			}
			if (selection === 'yearly_gift') {
				setPassword(makeid(10));
				await fetch(`${process.env.REACT_APP_BASE_URL}/auth/existingUser/${email}`, {
					method: 'Get',
				})
					.then(res => res.json())
					.then(response => {
						if (response === 'OK') {
							setShowLoader(true);
							register(e);
							handleClickYearlyGift(e);
						} else {
							e.target.disabled = false;
							return setError(response);
						}
					});
			}
			if (selection === 'yearly3') {
				setPassword(makeid(10));
				await fetch(`${process.env.REACT_APP_BASE_URL}/auth/existingUser/${email}`, {
					method: 'Get',
				})
					.then(res => res.json())
					.then(response => {
						if (response === 'OK') {
							setShowLoader(true);
							register(e);
							handleClickYearly3(e);
						} else {
							e.target.disabled = false;
							return setError(response);
						}
					});
			}
			if (selection === 'yearly5') {
				setPassword(makeid(10));
				await fetch(`${process.env.REACT_APP_BASE_URL}/auth/existingUser/${email}`, {
					method: 'Get',
				})
					.then(res => res.json())
					.then(response => {
						if (response === 'OK') {
							setShowLoader(true);
							register(e);
							handleClickYearly5(e);
						} else {
							e.target.disabled = false;
							return setError(response);
						}
					});
			}
			if (selection === 'yearly10') {
				setPassword(makeid(10));
				await fetch(`${process.env.REACT_APP_BASE_URL}/auth/existingUser/${email}`, {
					method: 'Get',
				})
					.then(res => res.json())
					.then(response => {
						if (response === 'OK') {
							setShowLoader(true);
							register(e);
							handleClickYearly10(e);
						} else {
							e.target.disabled = false;
							return setError(response);
						}
					});
			}
		}
	};
	const location = useLocation();

	if (location.pathname == '/register') {
		document.body.style.overflow = 'auto';
	}

	return (
		<TopDiv>
			<SignLogWrapper>
				<LoginTab>
					<Link to='/login'>Log in</Link>
				</LoginTab>
				<SignupTab>Sign Up</SignupTab>
			</SignLogWrapper>
			<ContentWrapper>
				<LeftRegisterWrapper>
					<SignupText>10x Your Scripture Study Now with your own Research Assistant</SignupText>

					<div>
						<SideText style={{ marginBottom: 0 }}>
							Scripture Notes is a transformational study system allowing you to dig deeper into the scriptures and get more revelation than
							ever before. Completely customize your workspace for what you want to study and preserve your insights forever with our powerful
							note system.
						</SideText>

						<SideText style={{ marginBottom: 0 }}>
							Our free training and tutorials will dramatically increase your ability to ask good questions, search for answers, and make
							connections all across the scriptures. Then you will go deeper into learning about available resources like finding Hebrew and
							Greek meanings of words, and advanced tips and tricks to pull more out of the scriptures during your study time.
						</SideText>

						<SideText style={{ marginBottom: 0 }}>Don’t delay. Get started now on a deeper journey into the scriptures.</SideText>
					</div>
				</LeftRegisterWrapper>
				<LogoImage key='logo' src={ColorLogo} alt='' />
				<SignupText>FREE 14 Day Pro Trial </SignupText>
				<FormAndRightWrapper>
					<RegisterWrapper key='registerFrom'>
						{/* <LoginHelpMsg>Instant login, no passwords</LoginHelpMsg> */}
						<FormWrapper>
							<form
								onSubmit={register}
								style={{
									padding: '15px',
									backgroundColor: '#fff',
									height: 'calc(100% - 118px)',
									// boxShadow: '-1px 5px 5px rgba(0,0,0,0.3)',
									borderRadius: '5px 0 0 0',
								}}
							>
								{error !== '' && (
									<ErrorWrapper>
										<p>{error}</p>
									</ErrorWrapper>
								)}
								{gift !== false && <GiftLabel>Who are you gifting this membership to?</GiftLabel>}
								<FormGroup>
									{/* <SignupText> Register Here! </SignupText> */}
									<FormInputLabel>User Information</FormInputLabel>
									<FormInput
										placeholder='Email'
										name='email'
										type='email'
										id='inputEmail'
										value={email}
										onChange={e => setEmail(e.target.value.toLowerCase())}
										autoFocus={false}
									/>
									<FormInput
										placeholder='Name'
										name='name'
										type='text'
										id='inputName'
										value={name}
										onChange={e => setName(e.target.value)}
									/>
								</FormGroup>
								{gift === false && (
									<FormGroup>
										<FormInput
											placeholder='Password'
											name='password'
											type='password'
											id='inputPassword'
											value={password}
											onChange={e => setPassword(e.target.value)}
										/>
										<FormInput
											placeholder='Confirm password'
											name='passwordConfirm'
											type='password'
											id='inputPasswordConfirm'
											value={passwordConfirm}
											onChange={e => setPasswordConfirm(e.target.value)}
										/>
									</FormGroup>
								)}

								{gift !== false && (
									<GiftLabel>
										Please give us your first and last name to notify the recipient of your gift. We will copy you on that email so you can
										confirm they received it.
									</GiftLabel>
								)}
								{gift !== false && (
									<FormGroup>
										<FormInputLabel>Gift Giver Information</FormInputLabel>
										<FormInput
											placeholder='Giver Email'
											name='giver_email'
											type='giver_email'
											id='inputGiverEmail'
											value={giverEmail}
											onChange={e => setGiverEmail(e.target.value.toLowerCase())}
											autoFocus={false}
										/>
										<FormInput
											placeholder='Giver Name'
											name='giver_name'
											type='text'
											id='inputGiverName'
											value={giverName}
											onChange={e => setGiverName(e.target.value)}
										/>
									</FormGroup>
								)}
								<FormGroup>
									<FormInputLabel>Card Information</FormInputLabel>
									<FormCardInput
										style={{ width: '100%' }}
										placeholder='1234 1234 1234 1234'
										maxLength='16'
										name='card_num'
										type='tel'
										id='card_num'
										value={cardNum}
										onChange={e => setCardNum(e.target.value.toLowerCase())}
										autoFocus={false}
									/>
									<FormInput
										placeholder='MM/YY'
										name='card_exp'
										type='tel'
										id='card_exp'
										value={cardExp}
										onKeyUp={e => cardExpInput(e.target.value)}
										onChange={e => setCardExp(e.target.value)}
									/>
									<FormInput
										placeholder='CVC'
										name='card_cvc'
										type='tel'
										id='card_cvc'
										value={cardCvc}
										onChange={e => setCardCvc(e.target.value)}
									/>
								</FormGroup>

								<FormGroup>
									<FormInputLabel>Card Holder Name</FormInputLabel>
									<FormInput
										placeholder='Card Holder Name'
										name='card_holder'
										type='text'
										id='card_holder'
										value={cardHolder}
										onChange={e => setCardHolder(e.target.value)}
									/>
								</FormGroup>
								<FormGroup>
									<FormInputLabel>Address</FormInputLabel>
									<Select 
									placeholder='Select Country'
									options={options} 
									value={country} 
									style={{
										width:'100%'
									}}
									onChange={changeHandler} />
									<FormInput
										placeholder='Zip Code'
										name='card_holder'
										type='text'
										id='postal_code'
										value={postalCode}
										onChange={e => setPostalCode(e.target.value)}
									/>
								</FormGroup>
							</form>
						</FormWrapper>
					</RegisterWrapper>

					<RightRegisterWrapper>
						{/* <SignupText>FREE 14 Day Pro Trial </SignupText> */}
						<PermissionInputWrapper id='gift'>
							<input
								type='checkbox'
								name='gift'
								value={gift}
								onChange={e => {
									setGift(e.target.checked);
									setPassword(makeid(10));
								}}
							/>{' '}
							This is a gift for someone else
						</PermissionInputWrapper>
						{gift === false && (
							<SideText>
								Choose your subscription option. You will not be billed until after your free trial expires. You may cancel at any time and
								revert to our powerful free version by going to Account Settings in your user dashboard. You can also upgrade at any time.
							</SideText>
						)}
						{gift !== false && <GiftText>Single-Year: (Optional to auto-renew beyond 1 year)</GiftText>}
						{gift === false && (
							<PermissionInputWrapper id='checkMonthly'>
								<input type='radio' name='checkMonthly' checked={checkMonthly} onChange={handleChangeMonthly} /> Billed Monthly $4.95
							</PermissionInputWrapper>
						)}
						{gift === false && (
							<PermissionInputWrapper id='chekcYearly'>
								<input type='radio' name='chekcYearly' checked={checkYearly} onChange={handleChangeYearly} /> Billed Annually $49.95 (Get 2
								Months Free)
							</PermissionInputWrapper>
						)}
						{gift !== false && (
							<PermissionInputWrapper id='checkMonthlyGift'>
								<input type='radio' name='checkMonthlyGift' checked={checkMonthlyGift} onChange={handleChangeMonthlyGift} /> Monthly –
								($4.95/month for 1 year)
							</PermissionInputWrapper>
						)}
						{gift !== false && (
							<PermissionInputWrapper id='checkYearlyGift'>
								<input type='radio' name='checkYearlyGift' checked={checkYearlyGift} onChange={handleChangeYearlyGift} /> Annual –
								($49.95/year 17% savings)
							</PermissionInputWrapper>
						)}
						{gift !== false && (
							<PermissionInputWrapper id='renewGift'>
								<input type='checkbox' name='renewGift' value={renewGift} onChange={e => setRenewGift(e.target.checked)} /> Check this box to
								automatically renew your gift at end of 1-year term
							</PermissionInputWrapper>
						)}

						<GiftText>Multi-Year: (not automatically renewed)</GiftText>

						<PermissionInputWrapper id='checkYearly3'>
							<input type='radio' name='checkYearly3' checked={checkYearly3} onChange={handleChangeYearly3} /> 3 Years - $129.95 (28% savings)
						</PermissionInputWrapper>

						<PermissionInputWrapper id='checkYearly5'>
							<input type='radio' name='checkYearly5' checked={checkYearly5} onChange={handleChangeYearly5} /> 5 Years - $199.95 (33% savings)
						</PermissionInputWrapper>

						<PermissionInputWrapper id='checkYearly10'>
							<input type='radio' name='checkYearly10' checked={checkYearly10} onChange={handleChangeYearly10} /> 10 Years - $349.95 (42%
							savings)
						</PermissionInputWrapper>

						<PermissionInputWrapper id='acknowledgement'>
							<input type='checkbox' name='acknowledgement' value={acknowledgement} onChange={e => setAcknowledgement(e.target.checked)} /> I
							agree to the{' '}
							<a target='_blank' rel='noopener noreferrer' href='https://scripturenotes.com/terms-and-conditions'>
								terms and conditions
							</a>{' '}
							of the site.
						</PermissionInputWrapper>
						<ButtonHolder>
							{showLoader && (
								<Loader
									type='TailSpin'
									color='#fff'
									height={25}
									width={25}
									style={{
										position: 'absolute',
										right: '34px',
										bottom: '10px',
										zIndex: '999',
									}}
								/>
							)}
							{checkMonthly ? (
								<FormBtn
									onClick={e => {
										handleExistingUser('monthly', e);
									}}
									type='submit'
								>
									Start Trial
								</FormBtn>
							) : checkYearly ? (
								<FormBtn
									onClick={e => {
										handleExistingUser('yearly', e);
									}}
									type='submit'
								>
									Start Trial
								</FormBtn>
							) : checkYearlyGift ? (
								<FormBtn
									onClick={e => {
										handleExistingUser('yearly_gift', e);
									}}
									type='submit'
								>
									Start Trial
								</FormBtn>
							) : checkMonthlyGift ? (
								<FormBtn
									onClick={e => {
										handleExistingUser('monthly_gift', e);
									}}
									type='submit'
								>
									Start Trial
								</FormBtn>
							) : checkYearly3 ? (
								<FormBtn
									onClick={e => {
										handleExistingUser('yearly3', e);
									}}
									type='submit'
								>
									Start Trial
								</FormBtn>
							) : checkYearly5 ? (
								<FormBtn
									onClick={e => {
										handleExistingUser('yearly5', e);
									}}
									type='submit'
								>
									Start Trial
								</FormBtn>
							) : checkYearly10 ? (
								<FormBtn
									onClick={e => {
										handleExistingUser('yearly10', e);
									}}
									type='submit'
								>
									Start Trial
								</FormBtn>
							) : (
								<FormBtn disabled={true}>Start Trial</FormBtn>
							)}
						</ButtonHolder>
					</RightRegisterWrapper>
				</FormAndRightWrapper>
			</ContentWrapper>
		</TopDiv>
	);
};

export default Signup;
